export const URL_MAIN_WEBSITE = 'https://crossref.org';
export const URL_LOGO_HORIZONTAL_RECTANGLE =
  'https://assets.crossref.org/logo/crossref-logo-200.svg';
export const PATH_ADMIN_SYSTEM_SUBMISSION_BY_ID =
  '/servlet/submissionAdmin?sf=detail&submissionID=';
export const URL_PRIVACY_POLICY =
  'https://www.crossref.org/operations-and-sustainability/privacy/';
export const URL_CC_BY_4 = 'https://creativecommons.org/licenses/by/4.0/';

export const DOI_DOT_ORG_PREFIX = 'https://doi.org/';

export const URL_SIMPLE_TEXT_QUERY =
  'https://apps.crossref.org/SimpleTextQuery';
export const URL_SIMPLE_TEXT_QUERY_FAQ =
  'https://www.crossref.org/documentation/register-maintain-records/maintaining-your-metadata/add-references/#00176';

export const URL_STATUS_PAGE = 'https://status.crossref.org';

export const URL_MAILTO_SUPPORT = 'mailto:support@crossref.org';

export const URL_DOCS_ROOT = 'https://www.crossref.org/documentation/';

export const URL_DOCS_RECORDS_TROUBLESHOOTING_SUBMISSIONS =
  'https://www.crossref.org/documentation/register-maintain-records/verify-your-registration/troubleshooting-submissions/';

export const URL_DOCS_RECORD_MANAGEMENT_TOOL =
  'https://www.crossref.org/documentation/register-maintain-records/record-registration-form/';

export const URL_METADATA_SEARCH = 'https://search.crossref.org';

export const URL_ROR_REGISTRY = 'https://ror.org/registry/';
