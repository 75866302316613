import axios, { AxiosResponse } from 'axios';
import { env } from '@/env';

export type DespositSuccessResponse = {
  statusCode: number;
  status: 'success';
  data: string;
};
export type DepositFailureReponse = {
  statusCode: number;
  status: 'fail';
  errorMessage: string;
  data: string;
};

export type DepositResponse = {
  statusCode: number;
  status: 'success' | 'failure';
  submissionId: number | undefined;
  deferredCitationsDiagnosticId: number | undefined;
  doi: string | undefined;
  recordCount: number;
  warningCount: number;
  failureCount: number;
  successCount: number;
  message: string;
  body: string;
};

export type DepositRequestBody = {
  usr: string;
  pwd?: string;
  role?: string;
  operation: 'doMDUpload';
  mdFile: Blob;
};

export type DepositRequestHeaders = {
  token: string;
  refresh_token: string;
};

export const postXML = async (
  request: DepositRequestBody,
  headers: DepositRequestHeaders
): Promise<AxiosResponse> => {
  const formData = new FormData();
  // Consider not mutating the original request object, here is how you could avoid it:
  const { usr, pwd, ...safeRequest } = request;

  // Append all other keys from safeRequest to formData
  Object.keys(safeRequest).forEach((key) => {
    const value = safeRequest[key];
    formData.append(key, value);
  });

  // token-based requests must specify the legacy role separately
  // formData.append('role', request.creds.role);

  const apiUrl = `${env().DEPOSIT_API_HOST}/v2/deposits`;

  // Set the necessary headers for the request
  const config = {
    headers: {
      Authorization: headers.token,
      refresh_token: headers.refresh_token,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(apiUrl, formData, config);
};

export const getDefaultDepositResponse = (): DepositResponse => {
  return {
    statusCode: 0,
    status: 'failure',
    submissionId: undefined,
    deferredCitationsDiagnosticId: undefined,
    doi: undefined,
    recordCount: 0,
    warningCount: 0,
    failureCount: 0,
    successCount: 0,
    message: 'An unknown error occurred. Please try your submission again.',
    body: '',
  };
};

export const deposit = async (
  request: DepositRequestBody,
  headers: DepositRequestHeaders,
  mock = false
): Promise<DepositResponse> => {
  // don't set { responseType: 'document' } because automatic response parsing will
  // only happen in the browser, not under node for unit tests
  const defaultResponse = getDefaultDepositResponse();
  if (mock) {
    defaultResponse.status = 'success';
    defaultResponse.message = '';
    return defaultResponse;
  }
  try {
    const response = await postXML(request, headers);
    const depositResponse = handleResponse(response, defaultResponse);
    return depositResponse;
  } catch (e) {
    let errorResponse = {};

    if (e && typeof e === 'object') {
      // if there's an axios error response (from a non 2xx HTTP response), use it
      if ('response' in e && e.response && typeof e.response === 'object') {
        errorResponse = e.response;
        // if not, set the JS Error message as the deposit response message
        // this is reached in the case of aborted connections, or other network failures
      } else if ('message' in e && typeof e.message === 'string') {
        defaultResponse.message = e.message;
      }
    }

    // we assume e.response is an AxiosResponse without a thorough check
    // FIXME: if that is not true the 'handleResponse' might crash
    const depositResponse = handleResponse(
      errorResponse as AxiosResponse,
      defaultResponse
    );
    return depositResponse;
  }
};

export const parseXML = (s: string): Document => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(s, 'application/xml');
  // print the name of the root element or error message
  const errorNode = doc.querySelector('parsererror');
  if (errorNode) {
    throw new Error('Error parsing XML string');
  }
  return doc;
};

export const handleResponse = (
  r: AxiosResponse,
  response: DepositResponse
): DepositResponse => {
  const messages: string[] = [];
  response.body = r.data;
  if (typeof r.data === 'string' && r.data.length > 0) {
    try {
      const doc = parseXML(r.data);
      const successCount = doc.evaluate(
        '//success_count[1]/text()',
        doc,
        null,
        XPathResult.NUMBER_TYPE
      );
      response.successCount = successCount.numberValue;
      const failureCount = doc.evaluate(
        '//failure_count[1]/text()',
        doc,
        null,
        XPathResult.NUMBER_TYPE
      );
      response.failureCount = failureCount.numberValue;
      const warningCount = doc.evaluate(
        '//warning_count[1]/text()',
        doc,
        null,
        XPathResult.NUMBER_TYPE
      );
      response.warningCount = warningCount.numberValue;
      const recordCount = doc.evaluate(
        '//record_count[1]/text()',
        doc,
        null,
        XPathResult.NUMBER_TYPE
      );
      response.recordCount = recordCount.numberValue;
      const messageNode = doc.evaluate(
        '//msg[1]/text()',
        doc,
        null,
        XPathResult.STRING_TYPE
      );
      const submissionId = doc.evaluate(
        '//submission_id[1]/text()',
        doc,
        null,
        XPathResult.NUMBER_TYPE
      );
      response.submissionId = submissionId.numberValue;
      const deferredCitationsDiagnosticId = doc.evaluate(
        '//record_diagnostic/citations_diagnostic/@deferred',
        doc,
        null,
        XPathResult.NUMBER_TYPE
      );
      response.deferredCitationsDiagnosticId = deferredCitationsDiagnosticId.numberValue;
      const doi = doc.evaluate(
        '//doi[1]/text()',
        doc,
        null,
        XPathResult.STRING_TYPE
      );
      response.doi = doi.stringValue;
      const messageStr = messageNode.stringValue;
      if (messageStr.length > 0) messages.push(messageStr);
    } catch (e: any) {
      if (r.status !== 200 && typeof r.data === 'string' && r.data.length > 0) {
        messages.push(r.data);
      }
      if (typeof e === 'object' && e !== null && 'message' in e) {
        messages.push(e.message);
      }
    }
  }
  response.statusCode = r.status || -1;
  switch (r.status) {
    case 200:
      if (response.failureCount === 0) {
        response.status = 'success';
      }
      break;
    case 400:
      messages.push('Bad request');
      break;
    case 401:
      messages.push('Unauthorized');
      break;
    case 403:
      break;
    case 500:
      messages.push('Internal server error');
      break;
    default:
  }
  response.message =
    messages.length > 0 ? messages.join(' ') : response.message;
  return response;
};
