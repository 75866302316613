import { FormData } from '@/forms/types';
import { mapContributorRoleToRelType } from '@/itemTree/RelationshipType';
import { Abstract as AbstractModel } from '@/models/Abstract';
import { FundingAward } from '@/models/FundingAward';
import { getOrNull } from '@/utils/helpers';

// Enums for journal issue keys
enum JournalIssueKeys {
  PublishedInPrint = 'publishedInPrint',
  PublishedOnline = 'publishedOnline',
  Volume = 'volume',
  Issue = 'issue',
  Doi = 'doi',
  ResourceUrl = 'resourceUrl',
}

type Contributor = {
  givenName?: string;
  familyName?: string;
  suffix?: string;
  affiliation?: {
    institution?: string;
    ror?: string;
  };
  orcid?: string;
  role?: string;
  sequence?: string;
};

type Abstract = {
  abstract: string;
  language: string;
};

type Funder = {
  awardNumber?: string;
  'funder-id'?: string;
  'funder-name'?: string;
  funderRorId?: string;
};

const normalizeContributorRole = (role: string) => {
  return role === 'first-author' || role === 'additional-author'
    ? 'author'
    : role;
};

const buildContributor = (c: Contributor) => {
  const r: any = {};
  if (c.givenName) r.givenName = c.givenName;
  if (c.familyName) r.surname = c.familyName;
  if (c.suffix) r.suffix = c.suffix;
  if (c.orcid) r.orcid = c.orcid;
  if (c.role) r.role = normalizeContributorRole(c.role);
  if (c.sequence) r.sequence = c.sequence;
  if (c.affiliation && (c.affiliation.institution || c.affiliation.ror)) {
    r.affiliation = {
      institution: c.affiliation.institution,
      ror: c.affiliation.ror,
    };
  }
  return r;
};

const buildAbstract = (abstractData: Abstract): AbstractModel | null => {
  return AbstractModel.create(
    abstractData.abstract,
    abstractData.language,
    false
  );
};

const buildFunder = (f: Funder): FundingAward | null => {
  const awardNumber = f.awardNumber ?? null;
  const funderId = f['funder-id'] ?? null;
  const funderName = f['funder-name'] ?? null;
  const funderRorId = f['funderRorId'] ?? null;

  const fundingAward = new FundingAward(
    awardNumber,
    funderId,
    funderName,
    funderRorId
  );

  if (fundingAward.isEmpty()) {
    return null;
  }

  return fundingAward;
};

/**
 * Maps journal metadata from a given input object to a structured output object.
 *
 * @param input - The input object containing journal data.
 * @returns The structured journal metadata object.
 */
const mapJournalMetadata = (input: any): any => {
  const jm = input.journal;

  const o = {
    fullTitle: getOrNull(jm, 'titles.fullTitle'),
    abbreviatedTitle: getOrNull(jm, 'titles.abbreviatedTitle'),
    doi: getOrNull(jm, 'doi'),
    resourceUrl: getOrNull(jm, 'resourceUrl'),
    printIssn: getOrNull(jm, 'issn.printIssn'),
    onlineIssn: getOrNull(jm, 'issn.onlineIssn'),
  };

  // Clean up journalMetadata to remove any undefined or null entries
  Object.keys(o).forEach((key) => {
    if (o[key] == null) {
      delete o[key];
    }
  });

  return o;
};

/**
 * Maps journal issue data from a given input object to a structured output object.
 *
 * @param input - The input object containing issue data.
 * @returns The structured journal issue object.
 */
const mapJournalIssue = (input: any): any => {
  const ji = input.issue;

  const o = {
    publishedInPrint: getOrNull(ji, JournalIssueKeys.PublishedInPrint),
    publishedOnline: getOrNull(ji, JournalIssueKeys.PublishedOnline),
    volume: getOrNull(ji, JournalIssueKeys.Volume),
    issue: getOrNull(ji, JournalIssueKeys.Issue),
    doi: getOrNull(ji, JournalIssueKeys.Doi),
    resourceUrl: getOrNull(ji, JournalIssueKeys.ResourceUrl),
  };

  // Clean up journalIssue to remove any undefined or null entries
  Object.keys(o).forEach((key) => {
    if (o[key] == null) {
      delete o[key];
    }
  });

  return o;
};

/**
 * Maps journal article data from a given input object to a structured output object.
 *
 * @param input - The input object containing article data.
 * @returns The structured journal article object.
 */
const mapJournalArticle = (input: any): any => {
  const ja = input.article;
  const jm = input.journal;

  if (!ja) return {};

  const o = {
    title: ja.title || null,
    contributors: ja.contributors
      ? ja.contributors
          .map(buildContributor)
          .filter((contributor) => contributor !== null) // Filter out null contributors
      : [],
    abstracts: ja.abstract
      ? ja.abstract.map(buildAbstract).filter((abstract) => abstract !== null) // Filter out null abstracts
      : [],
    publishedInPrint: ja.publicationDates
      ? ja.publicationDates.publishedInPrint
      : null,
    publishedOnline: ja.publicationDates
      ? ja.publicationDates.publishedOnline
      : null,
    doi: ja.doi || null,
    resourceUrl: ja.resourceUrl || null,
    funders: ja.funding
      ? ja.funding.map(buildFunder).filter((funder) => funder !== null) // Filter out null funders
      : [],
    license: ja.license || null,
    simCheckUrl: ja.simCheckUrl || null,
    references: ja.references
      ? ja.references.split('\n').reduce((acc, line) => {
          const trimmed = line.trim();
          if (trimmed) {
            acc.push(trimmed);
          }
          return acc;
        }, [])
      : null,
    firstPage: ja?.pageOrArticleNumber?.firstPageNumber || null,
    articleId: ja?.pageOrArticleNumber?.articleNumberOrId || null,
    crossmarkPolicy: jm?.crossmarkPolicyPage || null,
  };

  // Clean up journalArticle to remove any undefined or null entries
  Object.keys(o).forEach((key) => {
    if (o[key] == null) {
      delete o[key];
    }
  });

  return o;
};

export const mapData = (input: FormData) => {
  const o: any = {
    journalMetadata: mapJournalMetadata(input),
    journalIssue: mapJournalIssue(input),
    journalArticle: mapJournalArticle(input),
  };

  // Clean up empty objects
  if (Object.keys(o.journalMetadata).length === 0) delete o.journalMetadata;
  if (Object.keys(o.journalIssue).length === 0) delete o.journalIssue;
  if (Object.keys(o.journalArticle).length === 0) delete o.journalArticle;

  return o;
};
