import {
  JournalArticleMapper,
  mapJournalArticleToItemTree,
} from '@/itemTree/mapping/JournalArticleMapper';
import {ContentRegistrationType, UserInfo, XMLGenerationStrategy} from '@/common/types';
import { FormData } from '@/forms/types';
import {env} from "@/env";
import {
  XmlConversionBadInputError,
  XmlConversionError,
  XmlConversionNetworkError,
} from '@/errors';
import {
  EMAIL_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
  NAME_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN
} from "@/constants/contact";
import { mapData } from '../formToConvertApiMapper';

export const insertCitationsToXML = (
  inputXML: string,
  references: string[] | null | undefined
): string => {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const NAMESPACE = 'http://www.crossref.org/schema/5.3.1';

  const xmlDoc = parser.parseFromString(inputXML, 'application/xml');

  const parseError = xmlDoc.querySelector('parsererror');
  if (parseError) {
    throw new Error(
      `Invalid XML input: ${parseError.textContent?.trim() || 'Unknown error'}`
    );
  }

  const existingCitationList = xmlDoc.querySelector('citation_list');
  if (existingCitationList) {
    console.debug(
      'insertCitationsToXML: Citation list already exists in XML; no modifications made.'
    );
    return inputXML;
  }

  if (!Array.isArray(references)) {
    console.warn(
      'insertCitationsToXML: References is not an array. Returning original XML.'
    );
    return inputXML;
  }

  const referenceLines = references
    .map((ref) => ref.trim())
    .filter((ref) => ref.length > 0);

  if (referenceLines.length === 0) {
    console.warn(
      'insertCitationsToXML: No valid references provided after trimming. Returning original XML.'
    );
    return inputXML;
  }

  const journalArticle = xmlDoc.querySelector('journal_article');
  if (!journalArticle) {
    throw new Error('<journal_article> tag not found in the provided XML.');
  }

  const citationList = xmlDoc.createElementNS(NAMESPACE, 'citation_list');

  referenceLines.forEach((line, index) => {
    const citationElement = xmlDoc.createElementNS(NAMESPACE, 'citation');
    citationElement.setAttribute('key', `ref${index}`);

    const unstructuredCitation = xmlDoc.createElementNS(
      NAMESPACE,
      'unstructured_citation'
    );
    unstructuredCitation.textContent = line;

    citationElement.appendChild(unstructuredCitation);
    citationList.appendChild(citationElement);
  });

  journalArticle.appendChild(citationList);

  return serializer.serializeToString(xmlDoc);
};

export class JournalArticleXmlRenderingStrategy
  implements XMLGenerationStrategy
{
  async generateXML(
    recordType: ContentRegistrationType,
    data: FormData,
    prettyPrint = false,
    userInfo?: UserInfo
  ): Promise<string> {
    // Request XML from Manifold
    const mappedFormData = mapData(data);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const raw = JSON.stringify({
      doiBatchHead: {
        depositorName:
          userInfo?.depositorName ||
          NAME_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
        depositorEmail:
          userInfo?.depositorEmail ||
          EMAIL_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
        registrant: 'content-registration-form',
      },
      ...mappedFormData,
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow' as RequestRedirect,
    };

    try {
      const response = await fetch(env().convertApiEndpoint, requestOptions);
      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = JSON.stringify(errorResponse, null, 2);
        if (response.status === 400) {
          throw new XmlConversionBadInputError(`${response.statusText}`, {
            code: response.status,
            domainMessage: errorMessage,
          });
        }
        if (response.status === 500) {
          throw new XmlConversionError(response.statusText, {
            code: response.status,
          });
        }
        throw new Error('Unknown error');
      }
      const result = await response.text();
      const xmlWithCitations = insertCitationsToXML(
        result,
        mappedFormData?.journalArticle?.references
      );
      return xmlWithCitations;
    } catch (e) {
      // Determine if the error is a fetch-specific error
      if (e instanceof TypeError && e.message === 'Failed to fetch') {
        throw new XmlConversionNetworkError(e.message);
      } else {
        // Handle other unexpected errors
        throw e;
      }
    }
  }
}
